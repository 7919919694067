<template>
  <div class="card card-body" v-html="content"></div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  created() {
    var g = this;
    $.post(api + "/options")
      .then(function (r) {
        r = JSON.parse(r);
        g.content =
          r[
            window.location.pathname
              .split("page/")[1]
              .replace("another-services", "extra")
          ];
      })
      .catch(function (r) {
        alert(r);
      });
    var ga = window.location.pathname;

    var i = setInterval(() => {
      if (ga != window.location.pathname) {
        clearInterval(i);
        location.reload();
      }
    }, 1000);
  },
};
</script>